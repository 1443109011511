// out: false, main: ../style.scss
///////////////////////////////////////
///////// Standard Accordion //////////
///////////////////////////////////////
$add-icon-plus: false;
$add-icon-arrow: true;

@function url-friendly-colour-accordion($colour) {
  @return '%23' + str-slice('#{$colour}', 2, -1);
}

.accordion {
  .accordion-item {
    &.open {
      .accordion-title {
        &.icon {
          @if ($add-icon-plus == true){
            &::after {
              transform: rotate(-90deg);
            }
            &::before {
              transform: rotate(-90deg);
              opacity: 0;
            }
          }
          @if ($add-icon-arrow == true){
            &::before {
              transform: rotate(90deg);
            }
          }
        }
      }
    }
  }
  header {
    cursor: pointer;
    padding: 1rem;
    @if ($add-icon-plus == true){
      padding-left: 2.5em;
    }
    .accordion-title {
      margin: 0;
      position: relative;
      display: inline-block;
      &.icon {
        @if ($add-icon-plus == true){
          &::after {
            content: '';
            position: absolute;
            height: 20px;
            width: 2px;
            border-right: 2px solid #897842;
            top: 1px;
            left: -24px;
            opacity: 1;
            transition: all .5s;
          }
          &::before {
            content: '';
            position: absolute;
            height: 2px;
            width: 21px;
            border-top: 2px solid #897842;
            top: 10px;
            left: -34px;
            opacity: 1;
            transition: all .5s;
          }
        }
        @if ($add-icon-arrow == true){
          &::before {
            content: '';
            display: inline-block;
            height: 1rem;
            width: .5rem;
            margin-right: .4rem;
            margin-top: -.375rem;
            vertical-align: middle;
            transition: all .3s;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5.27 9.97'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;stroke:#{url-friendly-colour-accordion($color-1)};stroke-miterlimit:10;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eicon-arrow-red-right%3C/title%3E%3Cpolyline class='a' points='0.37 0.34 4.6 4.99 0.37 9.64'/%3E%3C/svg%3E") center center/contain no-repeat;
          }
        }
      }
    }
  }
  .accordion-content {
    display: none;
  }
}