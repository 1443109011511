/////////////////////////////////////
////// !!! ABHÄNGIGKEITEN !!! //////
// +++++ Benötigte Module: +++++ //
// _font-styles, _helper
// ++++++++++++++++++++++++++++ //
/////////////////////////////////

.teaser {
  @extend .font--headline-6;
  font-weight: bold;
  @include calc-font-size(22px, 20px);
  @include calc-space(4rem, 1.8rem, margin, top); // Margin-top
  @include calc-space(2rem, 1rem, margin, bottom); // Margin-bottom
}

.block-space {
  &--tb-sm {
    @include calc-space(2rem, 1.5rem, margin, top); // Margin-top
    @include calc-space(2rem, 1.5rem, margin, bottom); // Margin-bottom
  }
  &--t-sm {
    @include calc-space(2rem, 1.5rem, margin, top); // Margin-top
  }
  &--b-sm {
    @include calc-space(2rem, 1.5rem, margin, bottom); // Margin-top
  }
  &--tb-md {
    @include calc-space(3rem, 2rem, margin, top); // Margin-top
    @include calc-space(3rem, 2rem, margin, bottom); // Margin-bottom
  }
  &--t-md {
    @include calc-space(3rem, 2rem, margin, top); // Margin-top
  }
  &--b-md {
    @include calc-space(3rem, 2rem, margin, bottom); // Margin-top
  }
  &--tb-lg {
    @include calc-space(4rem, 3rem, margin, top); // Margin-top
    @include calc-space(4rem, 3rem, margin, bottom); // Margin-bottom
  }
  &--t-lg {
    @include calc-space(4rem, 3rem, margin, top); // Margin-top
  }
  &--b-lg {
    @include calc-space(4rem, 3rem, margin, bottom); // Margin-top
  }
}