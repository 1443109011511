// out: false, main: ../style.scss

// $section-color-even: lighten($dark-grey, 30%);
$section-color-even: $color-5;
// $section-color-odd: lighten($dark-grey, 40%);
$section-color-odd: $color-5--light;
$product-desc-height: 84px;
$product-price-color: $black;
$font-family: "Exo 2", Verdana, Helvetica, sans-serif;

//$font-family-serif: "utopia-std", serif;
$font-family-serif: "utopia-std-display", serif;
$font-family-serif-display: "utopia-std-display", serif;

.font {
  &--serif {
    //font-family: $font-family-serif;
    font-family: $font-family-serif-display;
    font-style: italic;
    //font-style: normal;
    font-weight: 400;
    letter-spacing: .08em;
    &--display {
      font-family: $font-family-serif-display;
      font-style: italic;
    }
  }
}


///////////////////////////////////////
/////////// Colors //////////////////
///////////////////////////////////////

.color-1 {
  color: $color-1;
  &--light {
    color: $color-1--light60;
  } 
  &--light2 {
    color: $color-1--light10;
  } 
}

.color-2 {
  color: $white;
  background-color: $color-2;
}

.color-3 {
  background-color: $color-3;
  &--light {
    background-color: $color-3--light;
  } 
}


///////////////////////////////////////
/////////////// Font //////////////////
///////////////////////////////////////


/* exo-2-300 - latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 300;
  src: url('/standards/font_v6/exo-2-v6-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Exo 2 Light'), local('Exo2-Light'),
       url('/standards/font_v6/exo-2-v6-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/standards/font_v6/exo-2-v6-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('/standards/font_v6/exo-2-v6-latin-300.woff') format('woff'), /* Modern Browsers */
       url('/standards/font_v6/exo-2-v6-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/standards/font_v6/exo-2-v6-latin-300.svg#Exo2') format('svg'); /* Legacy iOS */
}

/* exo-2-300 - latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 400;
  src: url('/standards/font_v6/exo-2-v6-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Exo 2 Light'), local('Exo2-Light'),
       url('/standards/font_v6/exo-2-v6-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/standards/font_v6/exo-2-v6-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('/standards/font_v6/exo-2-v6-latin-300.woff') format('woff'), /* Modern Browsers */
       url('/standards/font_v6/exo-2-v6-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/standards/font_v6/exo-2-v6-latin-300.svg#Exo2') format('svg'); /* Legacy iOS */
}

/* exo-2-500 - latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 500;
  src: url('/standards/font_v6/exo-2-v6-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Exo 2 Medium'), local('Exo2-Medium'),
       url('/standards/font_v6/exo-2-v6-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/standards/font_v6/exo-2-v6-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('/standards/font_v6/exo-2-v6-latin-500.woff') format('woff'), /* Modern Browsers */
       url('/standards/font_v6/exo-2-v6-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/standards/font_v6/exo-2-v6-latin-500.svg#Exo2') format('svg'); /* Legacy iOS */
}
/* exo-2-700 - latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 700;
  src: url('/standards/font_v6/exo-2-v6-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Exo 2 Bold'), local('Exo2-Bold'),
       url('/standards/font_v6/exo-2-v6-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/standards/font_v6/exo-2-v6-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/standards/font_v6/exo-2-v6-latin-700.woff') format('woff'), /* Modern Browsers */
       url('/standards/font_v6/exo-2-v6-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/standards/font_v6/exo-2-v6-latin-700.svg#Exo2') format('svg'); /* Legacy iOS */
}

///////////////////////////////////////
///////////// Defaults ////////////////
///////////////////////////////////////
              
.button, button {
  color: $white;
  letter-spacing: 0;
  width: 150px;
  &.hamburger {
    width: auto;
  }
  font-weight: 500;
  display: flex;
  line-height: 100%;
  justify-content: center;
  cursor: pointer;
  &.font--button-medium, &.font--button-small, &.button--solid {
    font-weight: 500;
    line-height: 100%;
  }
}

body {
  font-family: $font-family;
}
a {
  color: $black;
  text-decoration: none;
  transition: color 0.1s ease-in-out;
  &:hover {
    color: $dark-grey;
    transition: color 0.1s ease-in-out;
  }
}
h1 {
  &.resize {
    font-size: 2.5rem;
    @media (min-width: 32rem){
      font-size: 3rem;
    }
    @media (min-width: 48rem){
      font-size: 3.998rem;
    }
  }
}
h2 {
  &.resize {
    margin: 2rem 0;
  }
}
img[alt=""], img:not([alt]) {
  outline: 0;
}
q {
  quotes: "„" "“";
}
q:before {
    content: open-quote;
}
q:after {
    content: close-quote;
}


///////////////////////////////////////
////////// Section Styles /////////////
///////////////////////////////////////
.section-colored {
  padding-top: 6rem;
  padding-bottom: 6rem;
  &:nth-child(even){
    background-color: $section-color-even;
  }
  &:nth-child(odd){
    background-color: $section-color-odd;
  }
}
.product-overview-grd {
  .product {
    .product-desc {
      .price {
        &--discounted {
          color: $dark-grey;
        }
        &--old {
          color: $product-price-color;
        }
      }
      .favorite-icon {
        .icon {
          &:hover .fill {
            fill: $dark-grey;
          }
        }
      }
    }
  }
}
///////////////////////////////////////
/////////////// Swiper ////////////////
///////////////////////////////////////
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23%23E2E2E2'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23%23E2E2E2'%2F%3E%3C%2Fsvg%3E");
}

///////////////////////////////////////
////////////// Pageheader /////////////
///////////////////////////////////////
#pageheader{height:45vh;}

///////////////////////////////////////
///////////// Accordion ///////////////
///////////////////////////////////////
.accordion {
  &.open {
    .accordion-head {
      background-color: $dark-grey;
      color: $white;
      transition: all 0.3s ease-in-out;
      .accordion-title {
        &.icon__arrow {
          &::after {
            border-color: $white;
          }
        }
      }
    }
  }
  .accordion-head {
    transition: all 0.3s ease-in-out;
  }
  .accordion-head {
    background-color: $color-5--dark;
  }
}
///////////////////////////////////////
///////////// Tab Element /////////////
///////////////////////////////////////
.tab-element-container {
  .tab-nav {
    .tab {
      background-color: $dark-grey;
      color: $white;
      &.tab-active {
        background-color: $white;
        color: $black;
      }
    }
  }
  .tab-content-area {
    background-color: $white;
    color: $black;
  }
}
///////////////////////////////////////
/////////// Timeline Slider ///////////
///////////////////////////////////////
.timeline-slider {
  .slider-nav {
    background-color: $dark-grey;
    ul {
      .anchor {
        color: $white;
        &.is-active {
          color: $black;
        }
      }
    }
  }
  .custom-pagination-swiper-container {
    .swiper-wrapper {
      .swiper-slide {
        &:nth-child(odd) {
          background-color: $light-grey;
        }
        &:nth-child(even) {
          background-color: $light-grey;
        }
      }
    }
  }
}
///////////////////////////////////////
/////////////// Tables ////////////////
///////////////////////////////////////
.table {
  &.table--striped {
    & > tbody > tr:nth-of-type(odd) {
      background-color: #efefef;
    }
    & > tbody > tr:nth-of-type(even) {
      background-color: #f9f9f9;
    }
  }
  &--responsive {
    border:1px solid #dedede;
    thead {
      background:#78466E;
      color:#fff;
    }
    tfoot {
      background:#78466E;
      color:#fff;
    }
  }
  &--adaptive {
    border:1px solid #dedede;
    thead {
      background:#78466E;
      color:#ffffff;
    }
  }
}

///////////////////////////////////////
/////////////// Lists ////////////////
///////////////////////////////////////

ul:not(.list--unstyled) {
  padding: 0;
  margin: 0 0 1rem 0;
  //margin: 0;
  li {
    list-style: none;
    padding-left: 1.2em;
    position: relative;
    &::before {
      content: '';
      background: $color-1;
      display: block;
      height: .5em;
      width: .5em;
      left: -1.2em;;
      top: 1.05em;
      position: relative;
    }
  }
}