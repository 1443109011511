// out: false, main: ../style.scss
///////////////////////////////////////
///////// Spacing Classes /////////////
///////////////////////////////////////
.p0 {
  padding: 0;
}
.p0 {
  &--l {
    padding-left: 0;
  }
  &--r {
    padding-right: 0; 
  }
  &--t {
    padding-top: 0;
  }
  &--b {
    padding-bottom: 0;
  }
}
.p1 {
  padding: $p1;
}
.p1 {
  &--tb {
    padding-top: $p1;
    padding-bottom: $p1;
  }
  &--lr {
    padding-left: $p1;
    padding-right: $p1;
  }
  &--l {
    padding-left: $p1;
  }
  &--r {
    padding-right: $p1;
  }
  &--t {
    padding-top: $p1;
  }
  &--b {
    padding-bottom: $p1;
  }
}
.p1i {
  padding: $p1--i;
} 
.p1i {
  &--tb {
    padding-top: $p1--i;
    padding-bottom: $p1--i;
  }
  &--lr {
    padding-right: $p1--i;
    padding-left: $p1--i;
  }
  &--l {
    padding-left: $p1--i;
  }
  &--r {
    padding-right: $p1--i;
  }
  &--t {
    padding-top: $p1--i;
  }
  &--b {
    padding-bottom: $p1--i;
  }
}
.p1o {
  padding: $p1--o;
}
.p1o {
  &--tb {
    padding-top: $p1--o;
    padding-bottom: $p1--o;
  }
  &--lr {
    padding-right: $p1--o;
    padding-left: $p1--o;
  }
  &--l {
    padding-left: $p1--o;
  } 
  &--r {
    padding-right: $p1--o;
  }
  &--t {
    padding-top: $p1--o;
  }
  &--b {
    padding-bottom: $p1--o;
  }
}

.p2 {
  padding: $p2;
}
.p2 {
  &--tb {
    padding-top: $p2;
    padding-bottom: $p2;
  }
  &--lr {
    padding-left: $p2;
    padding-right: $p2;
  }
  &--l {
    padding-left: $p2;
  }
  &--r {
    padding-right: $p2;
  }
  &--t {
    padding-top: $p2;
  }
  &--b {
    padding-bottom: $p2;
  }
}
.p2i {
  padding: $p2--i;
} 
.p2i {
  &--tb {
    padding-top: $p2--i;
    padding-bottom: $p2--i;
  }
  &--lr {
    padding-right: $p2--i;
    padding-left: $p2--i;
  }
  &--l {
    padding-left: $p2--i;
  }
  &--r {
    padding-right: $p2--i;
  }
  &--t {
    padding-top: $p2--i;
  }
  &--b {
    padding-bottom: $p2--i;
  }
}
.p2o {
  padding: $p2--o;
}
.p2o {
  &--tb {
    padding-top: $p2--o;
    padding-bottom: $p2--o;
  }
  &--lr {
    padding-right: $p2--o;
    padding-left: $p2--o;
  }
  &--l {
    padding-left: $p2--o;
  } 
  &--r {
    padding-right: $p2--o;
  }
  &--t {
    padding-top: $p2--o;
  }
  &--b {
    padding-bottom: $p2--o;
  }
}


.p3 {
  padding: $p3;
}
.p3 {
  &--tb {
    padding-top: $p3;
    padding-bottom: $p3;
  }
  &--lr {
    padding-left: $p3;
    padding-right: $p3;
  }
  &--l {
    padding-left: $p3;
  }
  &--r {
    padding-right: $p3;
  }
  &--t {
    padding-top: $p3;
  }
  &--b {
    padding-bottom: $p3;
  }
}
.p3i {
  padding: $p3--i;
} 
.p3i {
  &--tb {
    padding-top: $p3--i;
    padding-bottom: $p3--i;
  }
  &--lr {
    padding-right: $p3--i;
    padding-left: $p3--i;
  }
  &--l {
    padding-left: $p3--i;
  }
  &--r {
    padding-right: $p3--i;
  }
  &--t {
    padding-top: $p3--i;
  }
  &--b {
    padding-bottom: $p3--i;
  }
}
.p3o {
  padding: $p3--o;
}
.p3o {
  &--tb {
    padding-top: $p3--o;
    padding-bottom: $p3--o;
  }
  &--lr {
    padding-right: $p3--o;
    padding-left: $p3--o;
  }
  &--l {
    padding-left: $p3--o;
  } 
  &--r {
    padding-right: $p3--o;
  }
  &--t {
    padding-top: $p3--o;
  }
  &--b {
    padding-bottom: $p3--o;
  }
}

.p4 {
  padding: $p4;
}
.p4 {
  &--tb {
    padding-top: $p4;
    padding-bottom: $p4;
  }
  &--lr {
    padding-left: $p4;
    padding-right: $p4;
  }
  &--l {
    padding-left: $p4;
  }
  &--r {
    padding-right: $p4;
  }
  &--t {
    padding-top: $p4;
  }
  &--b {
    padding-bottom: $p4;
  }
}
.p4i {
  padding: $p4--i;
} 
.p4i {
  &--tb {
    padding-top: $p4--i;
    padding-bottom: $p4--i;
  }
  &--lr {
    padding-right: $p4--i;
    padding-left: $p4--i;
  }
  &--l {
    padding-left: $p4--i;
  }
  &--r {
    padding-right: $p4--i;
  }
  &--t {
    padding-top: $p4--i;
  }
  &--b {
    padding-bottom: $p4--i;
  }
}
.p4o {
  padding: $p4--o;
}
.p4o {
  &--tb {
    padding-top: $p4--o;
    padding-bottom: $p4--o;
  }
  &--lr {
    padding-right: $p4--o;
    padding-left: $p4--o;
  }
  &--l {
    padding-left: $p4--o;
  } 
  &--r {
    padding-right: $p4--o;
  }
  &--t {
    padding-top: $p4--o;
  }
  &--b {
    padding-bottom: $p4--o;
  }
}

.m0 {
  margin: 0;
}
.m0 {
  &--l {
    margin-left: 0;
  }
  &--r {
    margin-right: 0;
  }
  &--t {
    margin-top: 0;
  }
  &--b {
    margin-bottom: 0;
  }
}
.m1 {
  margin: $m1;
}
.m1 {
  &--tb {
    margin-top: $m1;
    margin-bottom: $m1;
  }
  &--lr {
    margin-left: $m1;
    margin-right: $m1;
  }
  &--l {
    margin-left: $m1;
  }
  &--r {
    margin-right: $m1;
  }
  &--t {
    margin-top: $m1;
  }
  &--b {
    margin-bottom: $m1;
  }
}

.m2 {
  margin: $m2;
}
.m2 {
  &--tb {
    margin-top: $m2;
    margin-bottom: $m2;
  }
  &--lr {
    margin-left: $m2;
    margin-right: $m2;
  }
  &--l {
    margin-left: $m2;
  }
  &--r {
    margin-right: $m2;
  }
  &--t {
    margin-top: $m2;
  }
  &--b {
    margin-bottom: $m2;
  }
}

.m3 {
  margin: $m3;
}
.m3 {
  &--tb {
    margin-top: $m3;
    margin-bottom: $m3;
  }
  &--lr {
    margin-left: $m3;
    margin-right: $m3;
  }
  &--l {
    margin-left: $m3;
  }
  &--r {
    margin-right: $m3;
  }
  &--t {
    margin-top: $m3;
  }
  &--b {
    margin-bottom: $m3;
  }
}

.m4 {
  margin: $m4;
}
.m4 {
  &--tb {
    margin-top: $m4;
    margin-bottom: $m4;
  }
  &--lr {
    margin-left: $m4;
    margin-right: $m4;
  }
  &--l {
    margin-left: $m4;
  }
  &--r {
    margin-right: $m4;
  }
  &--t {
    margin-top: $m4;
  }
  &--b {
    margin-bottom: $m4;
  }
}