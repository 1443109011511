// out: false, main: ../style.scss

$measure-left: 5%;
$measure-right: 5%;

.measure {
  &--lg {
    @media (min-width: $viewport-xl){
      padding-left: 3.125rem;
    }
  }
  &--max {
    max-width: $measure-max;
    margin: 0 auto;
  }
  @media (min-width: $viewport-xl){
    &--left {
      padding-left: $measure-left;
    }
    &--right {
      padding-right: $measure-right;
    }
  }
}

.font--headline-1, h1 {
  @extend .font--uppercase;
  margin-bottom: .6em;
  line-height: 130%;
  @media (min-width: $viewport-xl){
    margin-bottom: .3em;
    line-height: 120%
  }
  main#start & {
    line-height: 100%;
    span {
      line-height: 120%;
    }
  }
  font-weight: 400;
}

.font--headline-2, h2 {
  @extend .font--uppercase;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: .35em;
}

.headline-has-addon {
  align-items: baseline;
}

.headline-addon {
  @extend .font--uppercase;
  font-weight: 700;
  @include calc-font-size(15px,13px);
  .headline-addon-descr {
    padding-right: 1rem;
    display: inline-block;
    padding-bottom: .5rem;
  }
  .button {
    text-align: center;
  }
  @media (min-width: $viewport-l){
    text-align: right;
  }
}

.font--headline-3 {
  font-weight: 700;
  margin-top: 0;
  .headline-has-addon & {
    margin-bottom: 1rem;
  }
}

.font--headline-5 {
  font-size: inherit;
  font-weight: 700;
  margin-top: 0;
}
/*
h3 {
  color: $color-1;
}
*/
a {
  text-decoration: none;
  color: $color-2;
  font-weight: 700;
  &:hover,
  &:active,
  &:focus {
    color: $color-2--light;
  }
  &.decode-phone {
    &:hover {
      text-decoration: none;
    }
  }
}


$p1--o--xl: 1.875rem;

.grd {
  //padding: 0 1rem;
}

.font--body-1, p {
  line-height: 150%;
}
.font--body-2, ul.font--body-2 {
  margin-bottom: $p1--o;
}

.fliesstext-grid {
  max-width: calc(960px + (2*#{$p1--o--xl}));
  &--satzspiegel-small {
    max-width: 960px;
  }
  &--font-bigger {
    font-size: 19px;  
  }
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
}

.grd-align-items--center {
  align-items: center;
}

.p1o {
  &--lr {
    &--maxmd {
      padding-left: 0;
      padding-right: 0;
      @media (min-width: $viewport-l){
        padding-right: $p1--o--xl;
        padding-left: $p1--o--xl;
      }
    }
  }
  &--r {
    &--maxmd {
      padding-right: 0;
      @media (min-width: $viewport-l){
        padding-right: $p1--o--xl;
      }
    }
  }
  &--tb {
    &--maxmd {
      padding-top: 0;
      padding-bottom: 0;
      @media (min-width: $viewport-l){
        padding-top: $p1--o--xl;
        padding-bottom: $p1--o--xl;
      }
    }
  }
  &--maxmd {
    padding: 0;
    @media (min-width: $viewport-l){
      padding: $p1--o--xl;
    }
  }
}
.p1i {
  
  &--lr {
    &--maxmd {
      padding-left: 0;
      padding-right: 0;
      @media (min-width: $viewport-l){
        padding-right: $p1--i--xl;
        padding-left: $p1--i--xl;
      }
    }
  }
}

.p0 {
  &--t {
    &--maxmd {
      @media (min-width: $viewport-l){
        padding-top: 0;
      }
    }
  }
  &--b {
    &--maxmd {
      @media (min-width: $viewport-l){
        padding-bottom: 0;
      }
    }
  }
}

.p2 {
  &--tb {
    &--maxmd {
      padding-top: 0;
      padding-bottom: 0;
      @media (min-width: $viewport-l){
        padding-top: 2rem;
        padding-bottom: 2rem;
      }
    }
  }
}


@media (min-width: $viewport-l){
  .p1 {
    &--l {
      &--maxmd {
        padding-left: 0;
        @media (min-width: $viewport-l){
          padding-left: 1rem;
        }
      }
    }
    &--r {
      &--maxmd {
        padding-right: 0;
        @media (min-width: $viewport-l){
          padding-right: 1rem;
        }
      }
    }
  }
  .p1i {
    padding: $p1--i--xl;
  } 
  .p1i {
    &--tb {
      padding-top: $p1--i--xl;
      padding-bottom: $p1--i--xl;
    }
    &--lr {
      padding-right: $p1--i--xl;
      padding-left: $p1--i--xl;
    }
    &--l {
      padding-left: $p1--i--xl;
    }
    &--r {
      padding-right: $p1--i--xl;
    }
    &--t {
      padding-top: $p1--i--xl;
    }
    &--b {
      padding-bottom: $p1--i--xl;
    }
  }
  .p1o {
    padding: $p1--o--xl;
  }
  .p1o {
    &--tb {
      padding-top: $p1--o--xl;
      padding-bottom: $p1--o--xl;
    }
    &--lr {
      padding-right: $p1--o--xl;
      padding-left: $p1--o--xl;
    }
    &--l {
      padding-left: $p1--o--xl;
    } 
    &--r {
      padding-right: $p1--o--xl;
    }
    &--t {
      padding-top: $p1--o--xl;
    }
    &--b {
      padding-bottom: $p1--o--xl;
    }
  }
  
  .p30pct {
    &--r {
      padding-right: 30%;
    }
  }
} 


.color--error {
  color: $color--error;
  border: 1px solid $color--error;
  padding: $p1--i--xl;
}
.color--success {
  color: $color--sucess;
  border: 1px solid $color--sucess;
  padding: $p1--i--xl;
}

form {
  label {
    padding-bottom: .2rem;
    display: inline-block;
  }
  input, textarea {
    border: none;
    margin-bottom: 0;
    &.error {
      border: 1px solid $color--error;
    }
  }
  textarea {
    min-height: 140px;
  }
  .select-wrapper {
    border-radius: 0;
    font-weight: 400;
    font-size: 1rem;
    padding: .4rem .75rem .5rem .75rem;
    width: 100%;
    background-color: #FFFFFF;
    &.error {
      border: 1px solid $color--error;
    }
  }
  .select-style {
    overflow: hidden;
    background-image: url("../img/arrow-down-select.png");
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 13px 6px;
    @supports (color: black){
      background-image: url("../img/arrow-down-select.svg");
    }
    select {
      min-width: 120%;
      background: transparent;
      margin: 0;
      line-height: normal;
      color: $color-1;
      outline: none;
      border: none;
      overflow: hidden;
      padding: 0;
      -webkit-appearance: unset;
    }
  }
}


.wortmarke {
  span {
    @extend .font--serif--display;
  }
}
/*
// OPENTABLE ANPASSUNGEN

#ot-reservation-widget {
  position: relative !important;
  z-index: 100 !important;
  bottom: 0 !important;
  width: 100% !important;
}
.ot-button {
  background-color: rgba($color-1, 0.8) !important; 
}
.ot-title {
  display: none !important;
}
.ot-dtp-picker {
  padding: 0 !important;
  width: 100% !important;
  color: $white !important;
  background: transparent !important;
}
.ot-date-picker {
  background-color: rgba($color-2, 0.8) !important; 
}
.ot-dtp-picker-selector, .ot-dtp-picker .ot-dtp-picker-button {
  //height: 2rem !important;
  height: auto !important;
  margin: .5rem 0 !important;
  border-bottom: none !important;
  &:focus {
    outline: $color-3--light;
  }
}

.ot-dtp-picker-selector-link {
  color: $color-1 !important; 
  padding: .3rem 0 !important;
  text-align: center;
  font-size: 13px !important;
  border: none !important;
  &::before, &::after {
    content:'' !important;
  }
}
.ot-dtp-picker .ot-dtp-picker-selector:hover .ot-dtp-picker-selector-link {
  outline-color: $color-3--light !important;
  outline-width: 1px !important;
} 

.ot-date-picker, .ot-select-label {
  background-color: $white !important; 
}
.ot-dtp-picker .ot-dtp-picker-form {
  border: none !important;
  background: transparent !important;
}
.ot-dtp-picker .ot-dtp-picker-button {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.ot-button {
  background: $color-2 !important; 
  border-color: $color-2 !important;
  margin-top: 1rem !important;
  padding: .3rem 0 !important;
  border: none !important;
  text-align: center;
  &:hover {
    background: $color-2--light !important;
  }
}

.ot-powered-by {
  height: 0px !important;
}

#opentable-error {
  display: none;
}
*/


// DATEPICKER ANPASSUNGEN

.picker--focused {
 border: 0;
}

.picker--opened .picker__holder {
  border-color: $color-1--light10;
  border-radius: 0;
  &:focus {
    outline: none;
  }
}

.picker {
  color: $color-1;
  left: 0;
  @media screen and (min-width: $viewport-xl) {
    left: $p1--o--xl;
  }
}
.picker__footer {
  //display: none;
  .picker__button--today,
  .picker__button--clear {
    display: none;
  }
  .picker__button--close {
    @extend .button--solid;
    margin-bottom: 1rem;
    &::before {
      content: '';
    }
  }
}

.picker__nav--next, .picker__nav--prev {
  top: -2px;
  @media (min-width: 24.5em){
    top: -2px;
  }
  &:hover {
    background: transparent;
  }
  &::before {
    transform: rotate(-135deg);
    border-bottom: 0;
    border-left: 0;
    border-top: 2px solid $color-1;
    border-right: 2px solid $color-1;
    height: 9px;
    width: 9px;
  }
  &.picker__nav--disabled {
    cursor: default;
    &::before {
      border-top: 2px solid $color-1--light10;
      border-right: 2px solid $color-1--light10;
    }
  }
}
.picker__nav--next:before {
  transform: rotate(45deg);
}

.picker__weekday {
  color: $color-2;
}

.picker--focused .picker__day--highlighted, 
.picker__day--highlighted:hover, 
.picker__day--infocus:hover, 
.picker__day--outfocus:hover {
  background: $color-2--light;
}

.picker--focused .picker__day--selected, 
.picker__day--selected, 
.picker__day--selected:hover {
  background: $color-2;
}

.picker__day--today:before {
  border-top: .5em solid $color-3;
}


.picker__day--highlighted {
  border-color: $color-2--light;
}


// SLIDER

.swiper-button-prev, .swiper-button-next {
  width: 14px;
  height: 41px;
  background-size: contain;
  top: 70%;
  #start & {
    height: 79px;
    width: 23px;
    top: 50%;
  }
  &.swiper-button-disabled {
    display: none;
  }
}
.swiper-button-prev, .swiper-container-rtl .swiper-button-next,
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  background-image: url("../img/arrow-slider.svg");
  margin-left: 0;
  left: -3.2rem;
  #start & {
    left: -5rem;
    background-image: url("../img/arrow-slider-big.svg");
    transition: left ease .2s;
    @media screen and (min-width: $viewport-m) {
      left: 1rem;
    }
    @media screen and (min-width: ($measure-lg+11rem)) {
      left: -5rem;
    }
  }
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  transform: translateY(-50%) rotate(180deg);
  margin-right: 0;
  left: auto !important;
  right: -3.2rem;
  #start & {
    right: -5rem;
    transition: right ease .2s;
    @media screen and (min-width: $viewport-m) {
      right: 1rem;
    }
    @media screen and (min-width: ($measure-lg+11rem)) {
      right: -5rem;
    }
  }
}

.swiper-pagination {
  &.swiper-pagination-bullets {
    background: transparent;
    //position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translate(-50%, 0%);
    -moz-transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, 0%);
    -o-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
    display: inline-block;
    padding: 1.063rem 0;
    width: auto;
    line-height: 100%;
    .swiper-pagination-bullet {
      background: rgba($white, 1);
      #start & {
        background: $color-1--light60;
      }
      opacity: 1;
      border-radius: 0;
      width: .5rem;
      height: .5rem;
      margin: 0 .375rem;
    }
    .swiper-pagination-bullet-active {
      background: rgba($color-1, 1);
      #start & {
        background: $white;
      }
    }
    main:not(#start) & {
      @media screen and (min-width: $viewport-xl) {
      display: none;
      }
    }
    #start & {
      @media screen and (min-width: $viewport-l) {
      display: none;
      }
    }  
  }

}

.slider-wrapper {
  overflow: hidden;
  position: relative;
}

.swiper-wrapper {
position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    box-sizing: content-box;
}
@media (min-width: $viewport-l) {
  .slider-v1 {
      margin-left: 2.4rem;
      margin-right: 2.4rem;
  }
}



section {
  color: $color-1;
  main:not(#start) & {
    @extend .measure--left;
  }
  //padding-bottom: 60px;
  padding-bottom: .5rem;
  h2 {
    max-width: 693px;
    display:inline-block;
  }
}

#scrollToTop {
  position: absolute;
  top: 0;
}

#header {
  position: fixed;
  top: 0;
  width: 100%;
  background: $white;
  z-index: 500;
  height: 54px;
  //transition: height ease-in .4s;
  .nav-is-sticky & {
    box-shadow: 0px 40px 40px 0px rgba(0,0,0,0.07);
  }
  .header-container {
    height: 100%;
    @extend .p1--lr;
  }
  #nav {
    order: 2;
    //width: 100%;
    @media screen and (min-width: $viewport-l) {
    padding-top: 20px;
    }
  }
  #langnav {
    order: 3;
    //width: 100%;
    padding-top: 20px;
  }

  
}


#bewertungen {
  display: none;
}
@media screen and (min-width: $viewport-xl) {
  #bewertungen {
    display: flex;
  }
}
main {
  @extend .measure--max;
  margin-top: 54px;
  transition: 0.3s ease-in;
}

.objectfit-header {
  height: 60vw;
}

.objectfit-img { 
  object-fit: cover;
  height: 100%;
  width: 100%;
  display: block;
}
.swiper-slide .slider-tile {
  max-height: 170px;
  overflow: unset;
}

#intro {
  position: relative;
  width: 100%;
  main:not(#start) & {
    max-height: 450px;
  }
  .headimage-slide {
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .objectfit-header#headimage {
    width: 100%;
    overflow: hidden;
    max-height: 450px;
  }  
  .objectfit-header#epura-headimage {
    width: 100%;
    overflow: hidden;
    max-height: 780px;
    .desc-wrapper {
      position: relative;
      top: auto;
      width: auto;
    }
  }
}

.scroll-down {
  height: 26px;
  display: flex;
  justify-content: center;
  .border {
    background: $white;
    position: relative;
    bottom: 1.5rem;
  }
  img {
    width: 100%;
    height: auto;
    display: block;
    bottom: 10px;
    position: relative;
    cursor: pointer;
  }
}

#contact-widget-wrapper {
  @media screen and (min-width: $viewport-xl) {
    height: 350px;
    position: fixed;
    top: 532px;
    //top: 53vh;
    body.no-headimg & {
      top: 300px;
    }
    display: flex;
    align-items: flex-end;
    z-index: 200;
  }
  @media (min-aspect-ratio: 16/9) and (max-width: 1600px) {
    top: 37vh;
  }
}
#contact-widget {
  display: none;
  font-size: 1.375rem;
  @media screen and (min-width: $viewport-xl) {
    display: block;
    //position: fixed;
    //top: 764px;
    //top: 63vh;
    body.start & {
      //top: 75vh;
    }
    body.no-headimg & {
      //top: 520px;
    }
    width: 280px;
    transition: width ease-in .2s;
    overflow: hidden;
    
    &.small-link {
      width: 83px; 
      transition: width ease-in .2s;
    }
  }
  

  .contact-widget-phone, .contact-widget-mail {
    position: relative;
    .widget-icon {
      cursor: pointer;
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
    a {
      color: $white;
      font-weight: 400;
      margin-left: 60px;
      position: relative;
      white-space: nowrap;
    }
  }
  
  .contact-widget-phone {
    padding: 1.2rem;
    padding-left: $p1--o--xl;
    padding-right: $p1--o--xl;
    border-bottom: 1px solid $color-2--light;
    .widget-icon {
      width: 22px;
      height: 31px;
      bottom: 15px;
      left: 29px;
      background-image: url("../img/icon-phone.png");
      @supports (color: black){
        background-image: url("../img/icon-phone.svg");
      }
    }
  }
  .contact-widget-mail {
    padding: 1.2rem;
    padding-left: $p1--o--xl;
    padding-right: $p1--o--xl;
    .widget-icon {
      width: 30px;
      height: 22px;
      bottom: 20px;
      left: 26px;
      background-image: url("../img/icon-mail.png");
      @supports (color: black){
        background-image: url("../img/icon-mail.svg");
      }
    }
  }
  
}
#header.nav-is-open {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //transition: height ease-in .2s;
  + #contact-widget-wrapper #contact-widget {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 600;
  }
}

#toggle-bookingwidget {
  align-self: center;
  order: 2;
  position: absolute;
  top: 0.3rem;
  min-width: 115px;
  font-size: 1.1rem;
  padding-left: 1.5rem;
  span {
    padding-right: 1.3rem;
    position: relative;
    &::after {
      height: 9px;
      width: 9px;
      border-top: 1px solid #3a394e;
      border-right: 1px solid #3a394e;
      display: block;
      right: 0;
      top: 22%;
      position: absolute;
      transform: rotate(135deg);
      content: '';
    }
  }
  @media screen and (min-width: $viewport-xl) {
    display: none;
  }
}



#booking-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  @media screen and (min-width: $viewport-xl) {
    body.no-headimg & {
      top: 300px;
    }
  }
  z-index: 500;
  display: none;
  box-shadow: 0 0 30px 30px rgba(0,0,0, .3);
  padding: 0;
  @media screen and (min-width: $viewport-xl) {
    //position: relative;
    right: auto;
    left: auto; 
    //top: 545px;
    //top: 53vh;
    display: flex;
    top: 532px;
    box-shadow: none;
    width: 100%;
    #start & {
      //position: absolute;
      //height: 100%;
    }
  }
  @media (min-aspect-ratio: 16/9) and (max-width: 1600px) {
    top: 37vh;
  }
  @media screen and (min-width: $viewport-xl) {
    padding: 0;
  }
}
.overlay-close {
  width: 24px;
  height: 24px;
  display: inline-block;
  position: absolute;
  right: $p1--i;
  top: $p1--i;
  cursor: pointer;
  .overlay-close-inner {
    top: 55%;
    transform: translateY(10px) rotate(45deg);
    background: $color-1;
    transition: transform 0.3s;
    &,
    &::before,
    &::after {
      content: '';

      display: block;
      width: 24px;
      height: 3px;
      margin-bottom: 1rem;
      opacity: 0.99;
    }
    &:last-child {
      transform: translateY(-10px) rotate(-45deg);
    }
    &::after {
      margin-bottom: 0;
    }
  }
  @media screen and (min-width: $viewport-xl) {
    display: none;
    #room-information & { 
      display: block;
    }
  }
}

#gastfreund {
  background-color: #3C4E65;
  color: #ffffff;
  width: 200px;
  position: absolute;
  z-index: 20;
  top: 221px;
  right: 5%;
  padding-top: 10px;
  height: 157px;
}

#gastfreund img {
  width: 160px;
  margin: 0px 14px;
}

#gastfreund-inner {
  padding: 0 30px;

}

#gastfreund-inner p {
  font-size: 12.4px;
  margin-bottom: 25px;
  line-height: 15px;
  margin-top: 0;
}

#gastfreund-inner a {
  background-color: #4e603e;
  color: #ffffff;
  padding: 11px 15px;
  transition: all .2s ease-in-out;
}

#gastfreund-inner a:hover {
  background-color: #6e7d61;
  cursor: pointer;
}

@media only screen and (max-width: 1199px) {
  #gastfreund{
    top: auto;
    bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  #gastfreund {
    position: relative;
    width: 100%;
    right: 0;
    top: 16px;
    bottom: auto;
  }

  #gastfreund div {
    width: 200px;
    margin: 0 auto;
  }

  #gastfreund-inner {
    width: 200px;

  }
}

.button--solid {
  font-weight: bold!important;
}

#booking {
  ::-webkit-input-placeholder { /* Edge */
    color: $color-1;
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $color-1;
  }
  ::placeholder {
    color: $color-1;
  }
  
  @media screen and (min-width: $viewport-xl) {
    height: 350px;
    display: flex;
    align-items: flex-end;
    position: absolute;
    right: 5%;
    body:not(.no-headimg) & {
      //top: -400px;
    }
    #start & {
      //top: auto;
      //bottom: 6rem;
    }
    width: 100%;
    width: 200px;
    transition: top ease .5s;
    .booking-shadow {
      width: 100%;
      box-shadow: 0 0 25px rgba(0,0,0, .12);
    }
  }
  @media screen and (min-width: 1400px) {
    body:not(.no-headimg) & {
      //top: -45px;
    }
    #start & {
      //top: auto;
      //bottom: 6rem;
    }
    transition: top ease .5s;
  }
  .booking-headline {
    background: $white;
    text-align: center;
    height: 54px;
    background: #fff;
    height: 54px;
    position: relative;
    display: flex;
    justify-content: center;
    @media screen and (min-width: $viewport-xl) {
      height: 50px;
      padding: $p1--i--xl;
    }
    h3 {
      padding: .75em 0 .5em 1.5rem;
      font-weight: 400;
      margin: 0;
      letter-spacing: .08em;
      position: absolute;
      top: .3rem;
      min-width: 115px;
      font-size: 1.1rem;
      span {
        cursor: pointer;
        padding-right: 1.3rem;
        position: relative;
        &::after {
          height: 9px;
          width: 9px;
          border-top: 1px solid #3a394e;
          border-right: 1px solid #3a394e;
          display: block;
          right: 0;
          top: 40%;
          position: absolute;
          transform: rotate(-45deg);
          content: '';
        }
      }
      @media screen and (min-width: $viewport-xl) {
        margin-top: 6px;
        font-size: 1.375rem;
        padding: 0;
        min-width: none;
        letter-spacing: 0;
        position: relative;
        top: 0;
        span {
          cursor: default;
          padding-right: .5rem;
          &::after {
            content: ':'; 
            transform: rotate(-0deg);
            border: none;
            height: auto;
            width: auto;
            top: 0;
          }
        }
      }
    }
  }
}
.booking-form {
  text-align: center;
  margin: .5em 0 1em 0;
  .grd-row {
    padding:.3rem 0;
    justify-content: center;
    float: left;
    background: white;
    font-size: 13px; 
    width: 47%;
    &#departure, &#children {
      float: right;
    }
    &#adults, &#children, &#persons {
    margin: .6em 0;
    }
    &#tabledate, &#persons {
      float: none;
      width: 100%;
    }
    &#persons {
      padding:.5rem 0;
    }
  }
  .datepicker, .datepicker_table {
    padding: 0;
    text-align: center;
    border: none;
    outline: none;
    cursor: pointer;
    width: 100%;
    font-size: 29px;
    font-weight: 700;
  }
  #table-month, #table-time {
    width: 50%;
    text-align: right;
    padding-right: 5%;
  }
  #table-time {
    text-align: left;
    padding: 0;
    padding-left: 5%;
  }
  #arrival-month, #departure-month, #persons-select {
    text-align: center;
    border: none;
    outline: none;
    width: 100%;
    font-size: 13px;
  }
  #adults-select, #children-select, #persons-select {
    padding: 0;
    margin: 0 auto;
    width: auto;
    text-align: justify;
    -moz-text-align-last: center;
    text-align-last: center;
    border: none;
    outline: none;
    cursor: pointer;
    //width: 100%;
    font-size: 13px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background:#ffffff;
  }
  select::-ms-expand {
    display: none;
  }
  #adults-select {
    max-width:42px;
  }
}

#start #intro {
  position: relative;
  background: $color-1;
  @media screen and (min-width: $viewport-l) {
    background: $white;
  }
  #header-text-overlay-wrapper {
    color: $white;
    padding-top: .5rem;
    background: $color-1;
    @include calc-font-size(16px,13px);
    @media screen and (min-aspect-ratio: 16/9) and (max-width: 1600px) {
      @include calc-font-size(14px,13px);
    }
    .wortmarke {
      @include calc-font-size(74px,44px);
      @media (min-aspect-ratio: 16/9) and (max-width: 1600px) {
        @include calc-font-size(48px,44px);
      }
    }
  }
  .objectfit-el {
    overflow: hidden;
  }
  .objectfit-header {
    width: 100%;
    height: auto;
    img {
      max-height: 835px;
      //height: auto;
      height: 100%;
      min-width: 100%;
    }
  }
  .full-width-header-container {
    position: relative;
  }
  #headslider {
    //max-height: 925px;
    max-height: 835px;
    height: 100%;
    .headimage-slide {
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }
  @media screen and (min-width: $viewport-l) {
    #header-text-overlay-wrapper {
      max-width: 400px;
      position: absolute;
      bottom: 0;
      left: 10%;
      z-index: 2;
      padding: 1rem 3rem 2rem 3rem;
      .button-wrapper span {
        display: inline-block;
      }
    }
    .objectfit-header {
      height: 80vh;
      max-height: 835px;
      #headslider {
        .headimage-slide {
          background-size: 100%;
          background-repeat: no-repeat;
          //height: 80vh; 
          //max-height: 835px;
        }
      }
    }
  }
    
}


hr {
  border-top: 1px solid $color-1;
  border-bottom: none;
  border-left: none;
  border-right: none;
  box-sizing: content-box;
  height: 0;
  margin: 1rem 0 .625rem 0;
}

// STARTSEITE
#start {
  .sbox {
    text-align: center;
    .objectfit-header {
      max-height: 450px;
      .sbox-img-small {
        max-height: 450px;
        width: 100%; 
      }
    }  
  }
  .font--body-1 {
    z-index: 20;
    &.leftbox, &.rightbox, &.fullwidthbox {
      @extend .p1o--lr;
      @media screen and (min-width: $viewport-xl) {
        min-height: 80px;
      }
    }
  }
  .button-wrapper {
    display: block;
    &.scroll-down {
      display: flex;  
    }
    z-index: 10;
    .border {
      background: $white;
    }
    .button, button {
      background: $color-1;
      border: 2px solid $color-1;
      &.font--bold {
        font-weight: 700;
      }
      &--white {
        background: $white;
        color: $color-1;
        border: 2px solid $white;
      }
      &:hover {
        background: $color-1--light60;
        border: 2px solid $color-1--light60;
      }
    }

  }
  .fullwidthbox {
    margin-bottom: 2rem;
  }
  .contact-logos {
    margin-top: 2rem;
    width: 100%;
    justify-content: space-evenly;
    .logo-wrapper {
      max-width: 30%;
      &:nth-child(2) {
        margin-left: 3%;
        margin-right: 3%;
      }
      @media screen and (min-width: $viewport-xl) {
        max-width: 120px;
      }
      border: 1px solid $color-1--light10;
      a {
        height: 100%;
        display: flex;
        align-items: center;
        max-width: 100%;
        img {
          height: auto;
          width: 100%;
        }
      }
    }
    
  }

  @media screen and (min-width: $viewport-l) {
    .sbox-index {
      text-align: center;
    }
    
  }
  @media screen and (min-width: $viewport-xl) {
    .sbox {
      padding-left: 0;
      padding-right: 0;
    }
    .sbox-index {
      text-align: center;
    }
    .font--body-1.leftbox {
      padding-left: 4rem;
      padding-right: 2rem;
    }
    .font--body-1.rightbox {
      padding-right: 4rem;
      padding-left: 2rem;
    }
    .fullwidthbox {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
    }
    .contact-descr {
      width: 50%;
      padding-left: 15%;
      text-align: right;
    }
    .contact-logos {
      margin-top: 0;
      width: 50%;
      padding-left: 7%;
      text-align: left;
      justify-content: left;
      .logo-wrapper {
        margin-right: 1rem;
      }
    }

  }
}

#epura {
  #epura-content-overlay {
    background: $light-grey;
    color: $color-1;
    .logo-epura {
      height: 25vw;
      max-height: 185px;
      width: 100%;
      background-image: url("../img/epura-gray-small.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      @supports (color: black){
        background-image: url("../img/epura-gray-small.svg");
      }
    }
  }
  @media screen and (min-width: $viewport-l) {
    position: relative;

    #epura-content-overlay {
      //justify-content: flex-end;
      flex-direction: column;
      align-items: flex-end;
      color: $white;
      position: absolute;
      background: transparent;
      top: 20%;
      right: 6%;
      width: 100%;
      .desc-wrapper {
        max-width: 440px;
      }
      .logo-epura {
        background-image: url("../img/epura-white-big.png");
        @supports (color: black){
          background-image: url("../img/epura-white-big.svg");
        }
      }
    }
  }
}


.site-label-margin {
  margin-top: -2em;
}
.site-label {
  background: $color-1;
  color: $white;
  display: inline-block;
  @include calc-font-size(20px,13px);
  padding: 2.308em 1.231em .692em 1.231em;
  position: relative;
  //top: -2.308em;
  margin-bottom: .8rem;
}

#contentimage-full {
  max-height: 450px;
  overflow: hidden;
}

#rooms-selector-buttons {
  .rooms-selector {
    padding-bottom: 1rem;
    position: relative;
    width: 45%;
  }
  .rooms-selector-button {
    padding: 1rem 0;
    border: 1px solid $color-1--light10;
    background: $white;
    outline: none;
    cursor: pointer;
    width: 100%;
    height: 20px;
    box-sizing: content-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: visible;
    .single, .double {
      height: 20px;
      display: block;
    }
    .single-font, .double-font {
      display: none;
    }
    .single {
      background: url(/standards/img/single--blue.svg) no-repeat center center, 16px 20px;
      width: 16px;
    }
    .double {
      background: url(/standards/img/single--blue.svg) no-repeat center left, 16px 20px, url(/standards/img/single--blue.svg) no-repeat center right, 16px 20px;
      width: 33px;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 1rem;
      left: calc(50% - 1.625rem);
      opacity: 0;
      width: 0; 
      height: 0;
      border-top: 1rem solid $color-1;
      border-left: 1.625rem solid transparent; 
      border-right: 1.625rem solid transparent; 
    }
    &.tab-active, &:hover {
      border: 1px solid $color-1;
      background: $color-1;
      color: $white;
      .single {
        background: url(/standards/img/single--white.svg) no-repeat center center, 16px 20px;
      }
      .double {
        background: url(/standards/img/single--white.svg) no-repeat center left, 16px 20px, url(/standards/img/single--white.svg) no-repeat center right, 16px 20px;
        width: 33px;
      }
      &::after {
        opacity: 1;
        bottom: -1rem;
        transition: all ease .2s;
        transition-delay: .1s;
      }
    }
  }
}

.tab-content-area {
  display: none;
  &.show {
    display: block;
  }
}


.button {
  text-align: center;
  min-width: 150px;
  width: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  &.hamburger {
    min-width: auto;
  }
  &--download {
    span {
      position: relative;
      padding-right: 20px;
      &::after {
        content: '';
        position: absolute;
        right: 0;
        height: 16px;
        width: 10px;
        background-image: url("../img/arrow-download.png");
        background-repeat: no-repeat;
        background-size: contain;
        @supports (color: black){
          background-image: url("../img/arrow-download.svg");
        }
      }
    }
  }
  &--ext-link {
    span {
      position: relative;
      padding-right: 21px;
      line-height: 100%;
      display: inline-block;
      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 13px;
        background-image: url("../img/arrow-extlink.png");
        background-repeat: no-repeat;
        background-size: contain;
        @supports (color: black){
          background-image: url("../img/arrow-extlink.svg");
        }
        background-position: center;
      }
    }
  }
  &.font--lowercase {
    padding-top: 0.45rem;
    padding-bottom: 0.55rem;
  }
}

// Lageplan
#map-container-overlay {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  @media screen and (min-width: $viewport-l) {
    bottom: 0;
    height: 100%;
  }
  z-index: 20;
  display: none;
  #room-information {
    background: $color-3--light;
    width: 100%;
    height: 100%;
    position: relative;
    display: none;
    .room-information-inner {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .masonry {
        column-count: auto;
        .item {
          padding-bottom: 0;
          padding-right: 0;
          padding-left: 0;
          padding-top: $p1--o;
          @media screen and (min-width: $viewport-l) {
            padding-left: $p1--o;
            padding-top: 0;
          }
          
        }
      }
      .grundriss-detail {
        width: 100%;
        height: 48%;
        @media screen and (min-width: $viewport-l) {
          height: 56%;
        }
        background: $color-3;
      }
    }
    .room-information-textcontainer {
      justify-content: space-between;
      .block-seats, .block-dimensions {
        padding-top: 1rem;
      }
    }
    .room-information_row {
      float: left;
      clear: both;
      width: 100%; 
      padding: .2rem .75rem;
      &:nth-child(even) {
        background: $color-3; 
      }
      .left {
        width: 80%;
        float: left;
      }
      .right {
        width: 20%;
        float: right;
      }
      @media screen and (min-width: $viewport-l) {
        .left {
          width: 85%;
        }
        .right {
          width: 15%;
        }

        &.row-dimensions {
          .left {
            width: 75%;
          }
            .right {
            width: 25%;
          }
        }
      }
    }
  }
}
#map_container, .interaktiver-lageplan {
  width:100%;
  position: relative;
}
.room-link {
  fill: transparent;
  opacity: 0;
}



///////////////////////////////////////
////// Masonry Image Grid /////////////
///////////////////////////////////////
.masonry {
  &.measure--lg {
    column-count: auto;
  }
  .item-content {
    height: auto;
  }
}
.sbox.item {
  &.p1o {
    height: 100%;
    padding-bottom: 0;
    @media screen and (min-width: $viewport-m) {
      padding-bottom: $p1--o;
    }
    @media screen and (min-width: $viewport-xl) {
      padding-bottom: $p1--o--xl;
    }
    &.p1i--t {
      padding-top: $p1--i;
    }
  }
}
.sbox .item-content {
  height: 100%;
}

#contact-map {
  padding: 0 0 70% 0;
  @media screen and (min-width: $viewport-l) {
    padding: 0 0 40% 0;
  }
}

.ext-link-container {
  .ext-link-descr {
    width: 100%;
    .img-wrapper, a:not(.fliesstextlink) {
      /*padding-bottom: $p1--o--xl;*/
      display: inline-flex;
      align-items: center;
      background: #ffffff;
      height: auto;
      min-height: 80px;
      margin-bottom: 1rem;
      @media screen and (min-width: $viewport-xl) {
        height: 100%;
        min-height: none;
        margin-bottom: 0;
      }
      float: left;
      margin-right: $p1--o--xl;
    }
    img {
      max-width: 152px;
      height: auto;
      padding: 1.35rem;
      &.objectfit-img {
        padding: 0;
        height: 100%;
      }
    }
  }
  .ext-link-button {
    width: 100%;
    padding-top: $p1--o--xl;
    .button--solid {
      transition: background-color .2s ease-in-out;
    }
    .button--solid:active, .button--solid:visited {
      color: $white;
    }
  }
}


@media screen and (min-width: $viewport-l) {
  .objectfit-header, .objectfit-container {
    // height: 40vh;
    max-height: 450px;
    &#epura-headimage {
      max-height: 780px;
    }
  }
  .ext-link-container {
    justify-content: space-between;
    align-items: center;
    .ext-link-descr {
      width: 70%;
      &.no-ext-link-button {
        width: 100%;
      }
      height: 85px;
      .img-wrapper {
        padding-bottom:0;
      }
    }
    .ext-link-button {
      width: 30%;
      text-align: right;
      padding-top: 0;

    }
    @media screen and (min-width: $viewport-xl) {
      .ext-link-button {
        padding-top: 0;
      }
    }
  }
}

@media screen and (min-width: $viewport-xl) {

  #header {
    .header-container {
      padding: 0;
    }
    position: fixed;
    top: 0;
    left: auto;
    right: auto;
    bottom: auto;
    height: 140px;
    transition: height 0.2s ease-in;
    .nav-is-sticky & {
      transition: height 0.3s ease-in-out;
      height: 84px;
    }
  }
  
  main { 
    margin-top: 140px;
    .nav-is-sticky & {
      margin-top: 84px;
    }
  }

  .site-label-margin {
    margin-top: -.6em;
  }
  .site-label {
    //top: -.6em;
    margin-bottom: .75rem;
  }

  #rooms-selector-buttons {
    .rooms-selector-button {
      padding: 1.8rem 0;
      .single, .double {
        //width: auto;
      }
      .single-font, .double-font {
        display: block;
        padding-left: .875rem;
        font-size: 1.25rem;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          right: -35px;
          top: 0;
          width: 25px;
          height: 20px;
          background: url(/standards/img/arrow-rooms--blue.svg) center right no-repeat,25px 20px;
        }
      }
      &.active, &:hover {
        .single-font, .double-font {
          color: $white;
          &::after {
            right: -35px;
            top: 0;
            width: 25px;
            height: 20px;
            background: url(/standards/img/arrow-rooms--white.svg) center right no-repeat,25px 20px;
          }
        }
      }
    }
  }
}

.standardbox {
  background: $color-3--light;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  border: none;
}
.standardbox-title {
  background-color: $color-3--light;
  text-transform: uppercase;
  padding-bottom: 1rem;
  margin: 0;
  color: $color-1;
  position: relative;
  display: block;
}
.standardbox-title-addon {
  padding-left: 1.2rem;
  @include calc-font-size(34px, 19px);
  text-transform: none;
}

.standardbox-content {
  @include calc-font-size(16px, 13px);
  line-height: 1.5em;
}

.download-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

///////////////////////////////////////
///////////// Accordion ///////////////
///////////////////////////////////////
.accordion {
  .accordion-item {
    background-color: $color-3;
    border-bottom: 1px solid $color-1--light60;
    &:last-child {
      border: none;
    }
    .accordion-head {
      //transition: all 0.3s ease-in-out;
      transition: none;
      cursor: pointer;
      background-color: $color-1;
      color: $white;
      .accordion-title {
        font-size: 15px;
        //padding-left: 1.375rem;
        &.icon {
          &__arrow {
            position: relative;
            display: flex;
            align-items: center;
            @media (min-width: $viewport-l){
              //padding: 0 0 0 1rem;
            }
            &::before {
              transform: rotate(45deg);
              margin-right: 1rem;
              content: '';
              position: relative;
              border-top: 2px solid $white;
              border-right: 2px solid $white;
              height: 8px;
              width: 8px;
              display: block;
              //left: 0;
              //top: 20%;
              @media (min-width: $viewport-l){
                height: 6px;
                width: 6px;
                border-width: 1px;
                //top: 30%;
              }
            }
          }
        }
      }
    }
    .accordion-content {
      display: block;
      overflow: hidden;
      height: 0;
      max-height: 0;
      transition: max-height 0.5s ease-in-out;
    }
    &.open {
      .accordion-head {
        background-color: $color-3;
        color: $color-1;
        //transition: all 0.3s ease-in-out;
        transition: none;
        .accordion-title {
          &.icon {
            &__arrow {
              &::before {
                transform: rotate(135deg);
                border-top: 2px solid $color-1;
                border-right: 2px solid $color-1;
                @media (min-width: $viewport-l){
                  border-width: 1px;
                }
              }
            }
          }
        }
      }
      .accordion-content {
        display: block;
        height: 100%;
        max-height: 350px;
        transition: max-height 0.5s ease-in-out;
      }
    }
  }
}

// Responsive Accordion.accordion-container
.accordions-responsive {
  display: inline-block;
  width: 100%;
  .accordion-container {
    .accordion {
      @extend .standardbox;
      margin-bottom: 2px;
      @media (min-width: $viewport-xl){
        margin-bottom: 36px;
      }
      .accordion-head {
        background: none;
        cursor: pointer;
        padding: 0;
        @media (min-width: $viewport-xl){
          cursor: default;
          //padding-right: 2rem;
        }
        .accordion-title {
          @extend .standardbox-title;
          padding-top: 1rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .button {
            //display: none;
            padding-left: 0.8rem;
            padding-right: 0.8rem;
            display: inline;
            min-width: 50px;
          }
          @media (min-width: $viewport-l){
            align-items: baseline;
            .button {
              display: flex;
              min-width: 150px;
            } 
          }

          .icon {
            &__arrow {
              //padding: 1rem 0 1rem 1.2rem;
              //padding: 1rem 0;
              display: flex;
              align-items: center;
              @media (min-width: $viewport-l){
                padding: 0 0 1rem 0;
              }
              &::before {
                content: '';
                transform: rotate(45deg);
                height: 9px;
                width: 9px;
                position: relative;
                margin-right: 1.2rem;
                border-top: 1px solid $color-1;
                border-right: 1px solid $color-1;
                display: block;
                //right: auto;
                //left: 0;
                //top: 42%;
                transition: all 0.3s ease-in-out;
                @media (min-width: $viewport-l){
                  display: none;
                }
              }
              &::after {
                display: none;
              }
            }
          }

        }
        .accordion-title-addon {
          @extend .standardbox-title-addon;
          padding-left: 1rem;
          @media (min-width: $viewport-xl){
            padding-left: 1.2rem;
          }
        }
        
      }
      .accordion-content {
        display: none;
        padding: 0;
        @media(min-width: $viewport-xl){
          display: block;
        }
        .item {
          border-bottom: 1px solid $color-1;
          p {
            margin: 0;
          }
          @media(min-width: $viewport-xl){
            border-bottom: 0;
          }
        }
        .roomprice-container {
          display: flex;
          flex-direction: column;
          @media(min-width: $viewport-xl){
            flex-direction: row;
          }
        }
        .accordion-roomprice-wrapper {
          display: flex;
          align-items: center;
          @include calc-font-size(18px, 13px);
        }
        .accordion-roomprice {
          font-size: 1.563rem;
          padding-left: 1rem;
          .font--between-serif {
            font-size: 1.125rem;
          }
        }
        .custom-bullet-list li {
          margin: 1rem 0;
        }
      }

      &.open {
        .accordion-head {
          .accordion-title {
            .icon {
              &__arrow {
                &::before {
                  transform: rotate(135deg);
                  transition: all 0.3s ease-in-out;
                  transform-origin: right;
                }
              }
            }
          }
        }
      }
    }
  }
}

footer {
  position: relative;
  #footer-nav-wrapper {
    border-top: 1px solid $color-1--light10;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  #footer-nav-measure {
    align-items: start;
    padding-right: $p1--o;
    padding-left: $p1--o;
    @media (min-width: $viewport-l){
      padding-right: $p1--o--xl;
      padding-left: $p1--o--xl;
    }
    @media (min-width: $measure-lg){
      padding-left: 0;
      padding-right: 0;
    }
  }
  .wortmarke {
    @extend .font--center;
    @include calc-font-size(24px, 20px);
    margin-bottom: 1.5rem;
    span:first-child {
      padding-bottom: .75rem;
      display: inline-block;
    }
    span:last-child {
      display: block;
      @include calc-font-size(21px, 16px);
    }
  }

  #back-to-top-btn-wrapper {
    position: relative;
    height: 12px;
    @extend .m1--b;
    
  }
  .back-to-top-btn {
    position: absolute;
    font-size: .688rem;
    font-weight: 500;
    color: $color-1--light60;
    &:hover {
      color: $color-1;
    }
    right: $p1--o;
    @media (min-width: $viewport-l){
      right: $p1--o--xl;
    }
    padding-right: 1rem;
    &::after {
      position: absolute;
      content: '';
      right: 0;
      width: 9px;
      height: 12px;
      background-image: url("../img/arrow-smoothescroll.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      @supports (color: black){
        background-image: url("../img/arrow-smoothescroll.svg");
      }
    }
  }

  @media screen and (min-width: $viewport-l) {
    #footer-nav-measure {
      align-items: flex-start;
    }
    .wortmarke {
      text-align: left;
      span:first-child {
        padding-right: 2.4em;
      }
      span:last-child {
        display: inline-block;
        padding-top: 0;
        text-align: left;
      }
    }
  }
  @media screen and (min-width: $viewport-xl) {

  }
}