// out: false, main: ../style.scss
#toggle-navigation {
  padding: 0;
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  position: absolute;
  right:20px;
  top:15px;
  z-index: 3;
  &:focus {
    outline: none;
  }
  .hamburger-box {
    width: 31px;
    height: 24px;
    display: inline-block;
    position: relative;
    .hamburger-inner {
      top: 55%;
      content: '';
      transition: transform 0.3s;
      &,
      &::before,
      &::after {
        display: block;
        width: 31px;
        height: 3px;
        margin-bottom: 7px;
        opacity: 0.99;
      }
      transform: translateY(0) rotate(0);
      &::after {
        margin-bottom: 0;
      }
    }
  }
  // Animation bei geöffneter Navi
  .navigation-open & {
    background-color: $white;
    .hamburger-box {
      .hamburger-inner {
        transform: translateY(10px) rotate(45deg);
        &:nth-child(2) {
          transform: scale(0);
        }
        &:last-child {
          transform: translateY(-10px) rotate(-45deg);
        }
      }
    }
  }
}

.navigation-open {
  height: 100%;
  overflow: hidden;
}



#main-navigation {
  //display: none;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;
  //background-color: $white;
  .nav-wrapper {
    order: 2;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    display: none;
    transition: height ease-in .2s;
    &.show {
      transition: none;
      display: block;
      transition: height ease-in .2s;
    }
  }
  a {
    text-decoration: none;
  }
  ul.lvl-0 {
    //display: none;
  }

  // LVL1 Main Navigation
  .lvl-1 {
    &.entry {
      padding-bottom: .6rem;
      padding-top: .6rem;
      white-space: nowrap;
      &::after {
        border-bottom: 1px solid  $color-1;
      }
      .nav-link-wrapper {
        padding:0;
        margin-right: 0;
        position: relative;
      }
      .nav-link {
        display: inline;
        white-space: nowrap;
        @include calc-font-size(15px,19px);
        font-weight: 500;
        line-height: 100%;
        text-align: right;
      }

      &.active, &:hover {
        a.nav-link, a.nav-link:hover {
          color:  $color-1;
        }
      }
      
    }
  }
}
#logo {
  height: 73px;
  width: 59px; 
  order: 1;
  background: url(/standards/img/logo.svg) no-repeat top left /cover;
  position: relative;
  left: 0;
  top: 0;
}



@media screen and (min-width: $viewport-xl) {
  #logo {
    transform: none;
    transition: transform 0.2s ease-in;
    transform-origin: top left;
    height: 166px;
    width: 134px; 
    .nav-is-sticky & {
      transform: scale(0.65);
      transition: all 0.2s ease-in;
      transform-origin: top left;
      margin-left: -3%;
    }
  }

  #toggle-navigation {
    padding: 0;
    display: none;
    z-index: 3;
  }
  #main-navigation {
    position: relative;
    height: 100%;
    margin-top: 0;
    z-index: 1;
    flex-direction: row;
    justify-content: space-between;
    .nav-wrapper {
      display: flex;
      transition: none;
      justify-content: space-between;
      width: 81.8%;
    }
    ul.lvl-0 {
      display: inline-flex;
      &#nav {
        width: 82%;
        justify-content: space-between;
      }
      &#nav, &#langnav {
        flex-direction: row;
        align-items: center;
      }
      &#langnav {
        width: auto;
        li {
          min-width: 2rem;
        }
      }
    }
    #nav, #langnav {
      // LVL1 Main Navigation
      .lvl-1 {
        &.entry {
          padding: 0 1rem 0 0;
          line-height: 1rem;
          .nav-link-wrapper {  
            margin-right: 0;
            padding: 22px 0 0 0;
            position: relative;
            //background: $white;
            &:not(.no-icon) {
              &::before {
                background: url(/standards/img/nav-hover.svg) no-repeat top center / 50px 34px;
                content: '';
                width: 100%; 
                height: 20px;
                position: absolute;
                //top: 24px;
                top: 0;
                opacity: 0;
                //z-index: -10; 
              }
            }
          }
          &.active, &:active {
            .nav-link-wrapper {
              &:not(.no-icon) {
                &::before {
                  transition: all .3s ease-in-out;
                  opacity: 1;
                }
              }
            }
            
          }
          a.nav-link {
            font-size: .938rem;
            background: $white;
            display: inline-block;
          }
          .toggle-subnavi {
            display: none;
          }

          &:hover, &.active-entry {
            a.nav-link, a.nav-link:hover {
              color:  $color-1;
            }
          }
        }
      }
    }
  }
}

footer {
  ul.list--unstyled {
    justify-content: space-evenly;
    li {
      display: inline-block;
      @include calc-font-size(11px,13px);
      padding: 0 5px;
      line-height: 2em;
    }
  }
  @media screen and (min-width: $viewport-l) {
    ul.list--unstyled {
      justify-content: start;
      margin-left: -2rem;
      li {
        padding: 0 0 0 2rem;
      }
    }
  }
  @media screen and (min-width: $viewport-xl) {
    ul.list--unstyled {
      justify-content: flex-end;
      margin: 0;
      li {
        //padding-left: 0;
       line-height: 2.5em
      }
    }
  }
}