// out: style.min.css, compress: true, sourceMap: false, autoprefixer: last 3 versions, groupmedia: true

///////////////////////////////////////
///////////// Variables ///////////////
///////////////////////////////////////
@import "components/vars";
///////////////////////////////////////
///////////// libraries ///////////////
///////////////////////////////////////
@import "components/lib/aos";
@import "components/lib/hamburgers/hamburgers";
@import "components/lib/hover";
@import "components/lib/simplelightbox";
@import "components/lib/swiper"; 
@import "components/lib/pickadate/classic"; 
@import "components/lib/pickadate/classic.date"; 

///////////////////////////////////////
//////////////// Theme ////////////////
/////////////////////////////////////// 
@import "components/theme";  
 
///////////////////////////////////////
/////////////// General ///////////////
///////////////////////////////////////
@import "components/normalize"; 
@import "components/variables"; 
@import "components/grid"; 
@import "components/resets";   
@import "components/forms";
@import "components/utility";  
@import "components/margins-paddings"; 
@import "components/font-styles";  
@import "components/blocks";  
@import "components/buttons"; 
@import "components/lists-tables";   

///////////////////////////////////////
/////////////// Modules ///////////////
///////////////////////////////////////
@import "components/modules/accordions"; 
@import "components/modules/accordions-responsive"; 
//@import "components/modules/footer-v1";  
@import "components/modules/gallery";
@import "components/modules/obj-fit-header";  
@import "components/modules/slider-v1"; 
//@import "components/modules/navigation-v1"; 
@import "components/eggers-navigation";
@import "components/main-styles";
 
