// out: false, main: ../style.scss


///////////////////////////////////////
/////////// Viewport //////////////////
///////////////////////////////////////
$viewport-m: 32rem !default;
$viewport-l: 48rem !default;
//$viewport-xl: 64rem !default;
$viewport-xl: 75rem !default;


///////////////////////////////////////
//////////////// Font /////////////////
///////////////////////////////////////
$font-size: 16px !default;
$font-weight: 300 !default;
$font-color: #000 !default;
$font-family:  "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$link-color: rgb(209, 209, 209) !default;
 