// out: false, main: ../style.scss

////////////////////////////////////////////
// ************* DEBUG MODE ************ //
//////////////////////////////////////////
$debug-mode: false !default;
////////////////////////////////////////////
// *************** COLORS ************** //
//////////////////////////////////////////
$color-1: rgba(58, 57, 78, 1) !default;
$color-1--dark: rgba(58, 57, 78, 1) !default;
$color-1--light: rgba(137, 136, 149, 1) !default;
$color-1--light60: rgba(137, 136, 149, 1) !default; // Blau 60%
$color-1--light10: rgba(235, 235, 237, 1) !default; // Blau 10%

$color-2: rgba(78, 96, 62, 1) !default; // Grün
$color-2--dark: rgba(78, 96, 62, 1) !default;
$color-2--light: rgba(110, 125, 97, 1) !default;

$color-3: rgba(218, 213, 208, 1) !default; // Sand
$color-3--dark: rgba(218, 213, 208, 1) !default;
$color-3--light:  rgba(233, 230, 227, 1) !default; // Sand 60%

$color-4: rgba(56, 178, 198, 1) !default;
$color-4--dark: rgba(34, 118, 126, 1) !default;
$color-4--light: rgba(165, 210, 210, 1) !default;

$color-5: rgba(180, 170, 150, 1) !default;
$color-5--dark: rgba(112, 107, 98, 1) !default;
$color-5--light: rgba(225, 218, 200, 1) !default;

$black: rgb(0, 0, 0) !default;
$white: #fff !default;
$dark-grey: #707070 !default;
$light-grey: #E3E3E3 !default;

$color--error: rgb(202, 26, 35) !default;
$color--sucess: rgb(15, 134, 35) !default;

////////////////////////////////////////////
// ************ FONT STYLES ************ //
//////////////////////////////////////////
$headline-1--min: 19px !default;
$headline-1--max: 59px !default;
$headline-1--letter-spacing: 0 !default;

$headline-2--min: 27px !default;
$headline-2--max: 37px !default;
$headline-2--letter-spacing: 0 !default;

$headline-3--min: 14px !default;
$headline-3--max: 21px !default;
$headline-3--letter-spacing: 0 !default;

$headline-4--min: 14px !default;
$headline-4--max: 18px !default;
$headline-4--letter-spacing: 0 !default;

$headline-5--min: 16px !default;
$headline-5--max: 18px !default;
$headline-5--letter-spacing: 0 !default;

$headline-6--min: 14px !default;
$headline-6--max: 16px !default;
$headline-6--letter-spacing: 0 !default;

$sub-1--min: 16px !default;
$sub-1--max: 18px !default;
$sub-1--letter-spacing:0 !default;

$sub-2--min: 14px !default;
$sub-2--max: 16px !default;
$sub-2--letter-spacing: 0 !default;

$body-1--min: 13px !default;
$body-1--max: 16px !default;
$body-1--letter-spacing: 0 !default;

$body-2--min: 13px !default;
$body-2--max: 15px !default;
$body-2--letter-spacing: 0 !default;

$caption--min: 12px !default; 
$caption--max: 14px !default;
$caption--letter-spacing: 0 !default;

$font-button--min: 13px !default;
$font-button--max: 16px !default;
$font-button--letter-spacing: 0 !default;

$font-button-medium--min: 13px !default;
$font-button-medium--max: 16px !default;
$font-button-medium--letter-spacing: 0 !default;

$font-button-small--min: 9px !default;
$font-button-small--max: 12px !default;
$font-button-small--letter-spacing: 0 !default;
////////////////////////////////////////////
// ************** BUTTONS ************** //
//////////////////////////////////////////
$button-default-padding: .5em 0 !default;

$button-border-radius: 0 !default;

$button-fill: $color-2 !default;
$button-fill--hover: $color-2--light !default;
$button-transition: 0.2s ease-in-out !default;

$button-solid--color: $white !default;
$button-solid--color-hover: $white !default;

$button-outline--fill: transparent !default;
$button-outline--color: $black !default;
$button-outline--color-hover: $white !default;
$button-outline--border: 2px solid $color-5--dark !default;
$button-outline--border-hover: 2px solid transparent !default;
$button-outline--hover-fill: $color-5--dark !default;

$button-pill--border-radius: 50px !default;

$button-bubble-arrow-size: 10px !default;
// Button Icons
$button-icon-default-hover: false !default;
$button-icon-default-animation: ".hvr-forward" !default;
$button-icon-default-direction: "left" !default;
////////////////////////////////////////////
// ******** PADDINGS | MARGINS ********* //
//////////////////////////////////////////
$p1: 1rem !default;
$p1--i: 1rem !default;
$p1--o: 1.5rem !default;

$p1--i--xl: .5rem !default;
$p1--o--xl: 1.875rem !default;

$p2: 2rem !default;
$p2--i: 1.3rem !default;
$p2--o: 0.7rem !default;

$p3: 3rem !default;
$p3--i: 1.3rem !default;
$p3--o: 0.7rem !default;

$p4: 4rem !default;
$p4--i: 1.3rem !default;
$p4--o: 0.7rem !default;

$m1: 1rem !default;
$m2: 2rem !default;
$m3: 3rem !default;
$m4: 4rem !default;