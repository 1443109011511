// out: false, main: ../style.scss
// Vars
$measure-sm: 48rem;
$measure-md: 64rem;
//$measure-md: 75rem;
$measure-lg: 100rem;
$measure-max: $measure-lg;

// Helper Functions / Mixins
@function strip-unit($num) {
  @return $num / ($num * 0 + 1); 
}
@function calc-rem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}
@function calc-rem-to-px($size) {
  $remSize: strip-unit($size) * 16px;
  @return #{$remSize}rem;
}
@mixin calc-space($max-size, $min-size, $type, $direction){
  // Erzeugt ein responsives Padding / Margin mit Richtungsangabe (top, right, bottom, left)
  // Rechnung: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width])));
  #{$type}-#{$direction}: calc(#{$max-size} + #{((strip-unit($max-size) * 16) - (strip-unit($min-size)) * 16)} * ((100vw - #{calc-rem(320px)}) / (#{strip-unit($measure-max) * 16} - 320)));
  @media screen and (min-width: $measure-max) {
    #{$type}-#{$direction}: $max-size;
  } 
}


///////////////////////////////////////
///////////// Object Fit //////////////
///////////////////////////////////////
.objectfit-el {
  overflow: hidden;
  &.compat-object-fit {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .objectfit-picture {
      display: none;
      .objectfit-img {
        display: none;
        object-fit: none !important;
      }
    }
    .objectfit-img {
      display: none;
      object-fit: none !important;
    }
  }
  .objectfit-img { 
    object-fit: cover;
    height: 100%;
    width: 100%;
    display: block;
  }
}
///////////////////////////////////////
///////////// Measures ////////////////
///////////////////////////////////////
.measure {
  &--sm {
    max-width: $measure-sm;
    margin: 0 auto;
  }
  &--md {
    max-width: $measure-md;
    margin: 0 auto;
  }
  &--lg {
    max-width: $measure-lg;
    margin: 0 auto;
  }
}
///////////////////////////////////////
/////////// Hide Class ////////////////
///////////////////////////////////////
.hide-block {
  &--sm {
    display: none;
    @media (min-width: $viewport-m){
      display: block; 
    }
  }
  &--md {
    display: none;
    @media (min-width: $viewport-l){
      display: block; 
    }
  }
  &--lg {
    display: none;
    @media (min-width: $viewport-xl){
      display: block; 
    }
  }
}
.hide-inline-block {
  &--sm {
    display: none;
    @media (min-width: $viewport-m){
      display: inline-block; 
    }
  }
  &--md {
    display: none;
    @media (min-width: $viewport-l){
      display: inline-block; 
    }
  }
  &--lg {
    display: none;
    @media (min-width: $viewport-xl){
      display: inline-block;
    }
  }
}
///////////////////////////////////////
/////////// Float Img /////////////////
/////////////////////////////////////// 
.float-img {
  &--left {
    max-width: 100%; 
    height: auto; 
    margin-top: 5px;
    img {
      display: block;
    }
    @media (min-width: $viewport-l){
      max-width: 60%; 
      float: left;
      margin-right: 2rem;
    }
  }
  &--right {
    max-width: 100%; 
    height: auto; 
    margin-top: 5px;
    img {
      display: block;
    }
    @media (min-width: $viewport-l){
      max-width: 60%; 
      float: right;
      margin-left: 2rem;
    }
  }
}
///////////////////////////////////////
///////// Standard Boxshadow //////////
/////////////////////////////////////// 
.shadow {
  box-shadow:0 0 15px 0 rgba(0, 0, 0, 0.2);
} 
///////////////////////////////////////
/////////////// Columns ///////////////
/////////////////////////////////////// 
.columns {
  column-count: auto;
  column-gap: 2rem;
  columns: 19rem;
}