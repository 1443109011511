// out: false, main: ../style.scss

///////////////////////////////////////
/////////// Font Styles ///////////////
///////////////////////////////////////
@mixin calc-font-size($max-size, $min-size, $scale: 1){
  // Berechnet die Schriftgröße je nach Viewport Size. 
  // Wenn der Viewport größer als der max Wert wird, wird die Schrift größer skaliert.
  // Rechnung: calc([minimum size] + ([maximum size] - [minimum size] * [Skalierungsfaktor]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width])));
  font-size: calc(#{calc-rem($min-size)} + #{(strip-unit($max-size) - strip-unit($min-size)) * $scale} * ((100vw - #{calc-rem(320px)}) / (#{strip-unit(120em) * 16} - 320))); 
  @media screen and (min-width: 120em) {
    font-size: $max-size;
  } 
}
/////////////////////////////////////////
// .font basiert auf https://material.io/design/typography/the-type-system.html#type-scale
.font {
  // Font Weights
  &--light { 
    font-weight: 200; 
  }
  &--regular { 
    font-weight: 400;
  }
  &--bold {
    font-weight: 700;
  }
  &--extra-bold {
    font-weight: 900;
  }
  // Text Transforms
  &--uppercase {
    text-transform: uppercase;
  }
  &--lowercase {
    text-transform: lowercase;
  }
  &--capitalize {
    text-transform: capitalize;
  }
  &--italic {
    font-style: italic;
  }
  &--center {
    text-align: center;
  }
  &--left {
    text-align: left;
  }
  &--right {
    text-align: right;
  }
  // Headlines
  &--headline-1 {
    @include calc-font-size($headline-1--max, $headline-1--min);  
    margin-bottom: 0.25em; 
    margin-top: 0.2em; 
    letter-spacing: $headline-1--letter-spacing;
    line-height: 110%;
  }
  &--headline-2 {
    @include calc-font-size($headline-2--max, $headline-2--min);
    margin-bottom: 0.25em; 
    margin-top: 0.2em; 
    letter-spacing: $headline-2--letter-spacing; 
    line-height: 110%;
  }
  &--headline-3 {
    @include calc-font-size($headline-3--max, $headline-3--min);
    margin-bottom: 0.31em; 
    margin-top: 0.3em; 
    letter-spacing: $headline-3--letter-spacing; 
    line-height: 120%;
  }
  &--headline-4 {
    @include calc-font-size($headline-4--max, $headline-4--min);
    margin-bottom: 0.31em; 
    margin-top: 0.3em; 
    letter-spacing: $headline-4--letter-spacing;
    line-height: 120%;
  }
  &--headline-5 {
    @include calc-font-size($headline-5--max, $headline-5--min);
    margin-bottom: 0.31em; 
    margin-top: 0.3em; 
    letter-spacing: $headline-5--letter-spacing;
    line-height: 120%;
  }
  &--headline-6 {
    @include calc-font-size($headline-6--max, $headline-6--min);
    margin-bottom: 0.325em; 
    margin-top: 0.31em; 
    letter-spacing: $headline-6--letter-spacing; 
    line-height: 125%;
  }
  &--sub-1 {
    @include calc-font-size($sub-1--max, $sub-1--min);
    margin-bottom: 0.325em; 
    margin-top: 0.2em; 
    letter-spacing: $sub-1--letter-spacing;
    line-height: 130%;
  }
  &--sub-2 {
    @include calc-font-size($sub-2--max, $sub-2--min);
    margin-bottom: 0.35em; 
    margin-top: 0.3em; 
    letter-spacing: $sub-2--letter-spacing;
    line-height: 130%;
  }
  // Copytext
  &--body-1 {
    @include calc-font-size($body-1--max, $body-1--min);
    margin-top: 0.5em;
    margin-bottom: 1em;
    letter-spacing: $body-1--letter-spacing;
    line-height: 140%;
  }
  &--body-2 {
    @include calc-font-size($body-2--max, $body-2--min);
    margin-top: 0.8em;
    margin-bottom: 0.9em;
    letter-spacing: $body-2--letter-spacing;
    line-height: 1.4;
    line-height: 150%;
  }
  &--caption {
    @include calc-font-size($caption--max, $caption--min);
    display:block;
    margin-bottom: 0.5em;
    margin-top: 0.8em;
    letter-spacing: $caption--letter-spacing;
    line-height: 140%;
  }
  &--footer {
    @extend .font--body-2; 
  }
  &--button {
    @include calc-font-size($font-button--max, $font-button--min);
    margin-bottom: 0.325em; 
    margin-top: 0.31em; 
    letter-spacing: $font-button--letter-spacing; 
    font-weight: bold;
    line-height: 1.7;
  }
  &--button-medium {
    @include calc-font-size($font-button-medium--max, $font-button-medium--min);
    margin-bottom: 0.325em; 
    margin-top: 0.31em; 
    letter-spacing: $font-button-medium--letter-spacing; 
    font-weight: bold;
    line-height: 1.7;
  }
  &--button-small {
    @include calc-font-size($font-button-small--max, $font-button-small--min);
    margin-bottom: 0.325em; 
    margin-top: 0.31em; 
    letter-spacing: $font-button-small--letter-spacing; 
    font-weight: bold;
    line-height: 1.7;
  }
  &--error {
    color: $color--error;
  }
  &--sucess {
    color: $color--sucess;
  }
}

h1 {
  @extend .font--headline-1;
}
h2 {
  @extend .font--headline-2;
}
h3 {
  @extend .font--headline-3;
}
h4 {
  @extend .font--headline-4;
}
h5 {
  @extend .font--headline-5;
}
h6 {
  @extend .font--headline-6;
}
p {
  @extend .font--body-1;
}
figcaption {
  @extend .font--caption;
} 