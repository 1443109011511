// out: false, main: ../style.scss
*, *::after, *::before {
  box-sizing: border-box;
}
// https://github.com/mrmrs/fluidity/blob/master/sass/_responsive-utilities.scss
img,
canvas,
iframe,
video,
svg,
select,
textarea {
  max-width: 100%;
}

///////////////////////////////////////
/////////// Debug Mode ////////////////
///////////////////////////////////////
@if $debug-mode == true {
  img[alt=""],
  img:not([alt]) {
    outline: 5px solid #cc0000;
  }
}
///////////////////////////////////////
///////// Anchor Styles ///////////////
///////////////////////////////////////
.a {
  &--unstyled {
    text-decoration: none;
    background: transparent;
    transition: none;
    &:hover {
      color: inherit;
      transition: none;
    }
  }
}

a {
  color: $link-color;

  &:hover,
  &:focus,
  &:active {
    color: darken($link-color, 10%);
  }
}
///////////////////////////////////////
///////// Swiper Styles ///////////////
///////////////////////////////////////
.swiper-slide {
  height: auto;
  .slider-tile {
    overflow: auto;
    background-color: darken(#fff, 3%);
  }
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23%23E2E2E2'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23%23E2E2E2'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next {
  right: -1.5rem;
}
.swiper-button-prev {
  left: -1.5rem;
}
.swiper-button-next, .swiper-button-prev {
  display: none;
  top: 50%;
  transform: translateY(-50%);
  @media(min-width: $viewport-l){
    display: block;
  }
}
.swiper-notification {
  visibility: hidden;
}
.swiper-pagination {
  position: relative;
  width: 100%;
  padding-top: 1.8rem;
  .swiper-pagination-bullet {
    margin: 0.2rem;
  }
  .swiper-pagination-bullet-active {
    background: #000;
  }
}
///////////////////////////////////////
///////// Button Styles ///////////////
///////////////////////////////////////
button {
  border: 0;
  outline: 0;
}
///////////////////////////////////////
////////////////// Hr /////////////////
///////////////////////////////////////
hr {
  border: none;
  border-top: 1px solid $color-1;
}

